const initialState = {
    IsAuth: false,
    email: '',
    is_verified: false,
    offices: []
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ISAUTH':
            return {
                ...state,
                IsAuth: action.IsAuth
            }
        case 'SETEMAIL':
            return {
                ...state,
                email: action.email
            }
        case 'SETOFFICES':
            return {
                ...state,
                offices: action.offices
            }
        case 'SETISVERIFIED':
            return {
                ...state,
                is_verified: action.is_verified
            }
        default:
            return state;
    }
}
export default userReducer;