import { Component } from 'react';
import cookies from 'react-cookies';
import moment from 'moment';
var jwtDecode = require('jwt-decode');

class CustomFunctions extends Component {

    jsonParse = (jsonString) => {
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            return jsonString;
        }
    }

    validateEmail = (email) => {
        var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailReg.test(email);
    }

    validatePhone = (phone) => {
        var phoneformat = /^\d{10}$/; // eslint-disable-line
        return phone.match(phoneformat);
    }
    validateOTP = (otp) => {
        var otpRegx = /^\d{0,6}$/; // eslint-disable-line
        return otp.match(otpRegx);
    }

    phoneMasking(value) {
        let newVal = (value).replace(/\D/g, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length > 15) {
            newVal = newVal.substring(0, 15);
        }
        return newVal;
    };

    validateAmount = (amount) => {
        var amountFormat = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
        return amountFormat.test(amount)
    }

    loadScript = async (src) => {
        const ele = document.getElementById(src);
        if (ele) {
            ele.remove();
        }
        var script = document.createElement("script");
        script.id = src;
        script.src = src;
        script.type = "text/javascript";
        script.async = false;
        // script.type = "text/babel";
        // script.type = "text/jsx";
        //  document.getElementsByClassName("wrapper")[0].appendChild(script);
        await document.body.appendChild(script);
    }

    getUserData = async () => {
        try {
            const userdata = await cookies.load('usertoken');
            const decodedData = jwtDecode(userdata);
            return decodedData ? decodedData.obj : {};
        } catch (err) {
            return null;
        }
    }

    dataURItoBlob = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    formatOnlyAmount = (number, currency) => {
        var code = '';
        switch (currency) {
            case 'USD':
                code = 'en-US';
                break;
            default:
                code = 'en-US';
                break;

        }
        if (number) {
            return number.toLocaleString(code, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        }
        return 0;
    }

    formatDate = (dt, seperator = '-', format = 'ymd') => {
        let dateObj = new Date(dt);
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        month = month < 10 ? "0" + month : month;
        var day = dateObj.getDate();
        day = day < 10 ? "0" + day : day;
        var year = dateObj.getUTCFullYear();
        let newdate;
        switch (format) {
            case 'ymd':
                newdate = year + seperator + month + seperator + day;
                break;
            case 'dmy':
                newdate = day + seperator + month + seperator + year;
                break;
            case 'mdy':
                newdate = month + seperator + day + seperator + year;
                break;
            case 'dM':
                newdate = day + seperator + dateObj.toLocaleString('default', { month: 'short' });
                break;
            default:
                newdate = year + seperator + month + seperator + day;
                break;
        }
        return newdate;
    }

    getFinacialYearStartEndDate = (format = 'ymd') => {
        let dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var year = dateObj.getUTCFullYear();
        var yearNext = dateObj.getUTCFullYear();
        if (month < 4) {
            year = dateObj.getUTCFullYear() - 1;
        } else {
            yearNext = dateObj.getUTCFullYear() + 1;
        }
        var fd = new Date(year + '-' + '04-01');
        var ld = new Date(yearNext + '-' + '03-31')
        fd = this.formatDate(fd, "-", format);
        ld = this.formatDate(ld, "-", format)
        return { fd, ld }
    }

    getMonthStartEndDate = (format = 'ymd') => {
        let dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var year = dateObj.getUTCFullYear();
        var days = this.getDaysInMonth(year, month);
        var fd = new Date(year, month);
        var ld = new Date(year + '-' + month + '-' + days)
        fd = this.formatDate(fd, "-", format);
        ld = this.formatDate(ld, "-", format)
        return { fd, ld }
    }

    getWeekStartEndDate = (format = 'ymd') => {
        var curr = new Date; // get current date
        var first = curr.getDate() + 1 - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6

        var fd = new Date(curr.setDate(first)).toUTCString();
        var ld = new Date(curr.setDate(last)).toUTCString();
        fd = this.formatDate(fd, "-", format);
        ld = this.formatDate(ld, "-", format)
        return { fd, ld }
    }

    getDaysInMonth = function (month, year) {
        // Here January is 1 based
        //Day 0 is the last day in the previous month
        return new Date(year, month, 0).getDate();
        // Here January is 0 based
        // return new Date(year, month+1, 0).getDate();
    };

    getFormatedDate = (date, format) => {
        const arrFullMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const arrShortMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        format = format.toLowerCase();
        date = new Date(date);
        const getYear = (date.getFullYear()).toString();
        const getMonth = date.getMonth() + 1;
        const getDate = (date.getDate()).toString();
        format = format.replace("yyyy", getYear);
        format = format.replace("yy", getYear.substring(2));
        format = format.replace("mmmm", arrFullMonth[getMonth - 1]);
        format = format.replace("mmm", arrShortMonth[getMonth - 1]);
        format = format.replace("mm", getMonth < 10 ? '0' + getMonth : getMonth);
        format = format.replace("dd", getDate.length === 1 ? '0' + getDate : getDate);
        return format;
    }

    getStartOrEndDateFromDate = (type, date) => {
        let newDate = new Date(date);
        if (type === 'start') {
            return new Date(newDate.getFullYear(), newDate.getMonth(), 1);
        } else if (type === 'end') {
            return new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
        }
    }

    getDateByYearOrMonthOrDay = (date, type, dmy) => {
        let newDate = new Date(date);
        this.convertGetTime(newDate);
        if (type === 'year') {
            return new Date(newDate.setFullYear(newDate.getFullYear() + dmy));
        } else if (type === 'month') {
            return new Date(newDate.setMonth(newDate.getMonth() + dmy));
        } else if (type === 'day') {
            return new Date(newDate.setDate(newDate.getDate() + dmy));
        }
    }

    getDiffInMonth = (start_date, end_date) => {
        let months;
        months = (end_date.getFullYear() - start_date.getFullYear()) * 12;
        months -= start_date.getMonth();
        months += end_date.getMonth();
        return months;
    }

    getDataFromCookie = async (key_name) => {
        if (key_name) {
            return await cookies.load(key_name);
        }
        return '';
    }

    removeDataFromCookie = async (key_name) => {
        if (key_name) {
            await cookies.remove(key_name);
        }
    }

    doIfUserIsUnauthorized = async () => {
        await this.removeDataFromCookie('usertoken');
        await this.removeDataFromCookie('crypto_userToken');
        await sessionStorage.clear();
        //added by rushi(temp fix)
        if (window.location.pathname != '/login')
            window.location.pathname = '/login';
    }
    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    titleSubString = (str, len = 100) => {
        if (!str) return '';

        return str.substring(0, len) + (str.length > len ? "..." : '');
    }

    getTime = (date) => {
        var date = new Date(date);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    convertGetTime = (date) => {
        var date = new Date(date);
        var dt = date.getTime();
        var dtt = moment(dt).format('MM');
        return date;
    }
}

export default new CustomFunctions();