import React, { Component } from 'react';
// import './App.css';
import { withRouter } from "react-router";
import cookies from 'react-cookies';
import Routes from './routes';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.checkAuth();
  }

  checkAuth = async () => { 
    const token = await cookies.load('usertoken');
    var url = window.location.href;
    var arr = url.split('/');
    if (!token && !arr[3] == 'setpass') {
       this.props.history.push('/login');
       return;
    }
 }

  componentDidMount = async () => {
  }

  render() {
    const childProps = {}
    return (
      <React.Fragment>
        <Routes childProps={childProps} />
      </React.Fragment>
    )
  }
}

export default withRouter(App);