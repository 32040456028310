import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from './components/async.component';

const AsyncLogin = asyncComponent(() => import('./screens/Login/Login'));
const AsyncHome = asyncComponent(() => import('./screens/Home'));
const AsyncOnBoardTrack = asyncComponent(() => import('./screens/OnBoardTracker'));
const AsyncData = asyncComponent(() => import('./screens/Data'));
const AsyncDailyReport = asyncComponent(() => import('./screens/DailyReport'));
const AsyncService = asyncComponent(() => import('./screens/Service'));
const AsyncRequest = asyncComponent(() => import('./screens/Request'));
const AsyncNotFound = asyncComponent(() => import('./screens/NotFound/not-found.component'));
const AsyncLoginNew = asyncComponent(() => import('./screens/Auth/Login/Login'));
const AsyncLoginNew1 = asyncComponent(() => import('./screens/Auth/Login/LoginNew'));
const AsyncPass = asyncComponent(() => import('./screens/Auth/Login/Password'));
const AsyncOtp = asyncComponent(() => import('./screens/Auth/Login/Otp'));
const AsyncSetPassword = asyncComponent(() => import('./screens/Auth/Login/SetPassword'));
const AsyncForgetPassword = asyncComponent(() => import('./screens/Auth/Login/ForgetPassword'));
const SocialLinkSetPassword = asyncComponent(() => import('./screens/Auth/Login/SocialLinkSetPassword'));
const AsyncChangePassword = asyncComponent(() => import('./screens/Auth/Login/ChangePassword'));
const AsyncSocialLogin = asyncComponent(() => import('./screens/Auth/Login/Social'));
const AsyncProfile = asyncComponent(() => import('./screens/Auth/Login/Profile'));
const AsyncIvReport = asyncComponent(() => import('./screens/IvReport'));

const AppRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Component {...props} />
    )}
  />
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps }) => {

  return (
    <Switch>
      <AppRoute path="/loginnew" exact component={AsyncLogin} props={childProps} />
      <AppRoute path="/" exact component={AsyncHome} props={childProps} />
      <AppRoute path="/on-boarding-tracker" exact component={AsyncOnBoardTrack} props={childProps} />
      <AppRoute path="/data" exact component={AsyncData} props={childProps} />
      <AppRoute path="/daily-report/:filterBy" exact component={AsyncDailyReport} props={childProps} />
      <AppRoute path="/services" exact component={AsyncService} props={childProps} />
      <AppRoute path="/request" exact component={AsyncRequest} props={childProps} />
      {/* <AppRoute path="/login" exact component={AsyncLoginNew} props={childProps} /> */}
      <AppRoute path="/login" exact component={AsyncLoginNew1} props={childProps} />
      <AppRoute path="/password" exact component={AsyncPass} props={childProps} />
      <AppRoute path="/code" exact component={AsyncOtp} props={childProps} />
      <AppRoute path="/setpassword" exact component={AsyncSetPassword} props={childProps} />
      <AppRoute path="/forgetpassword" exact component={AsyncForgetPassword} props={childProps} />
      <AppRoute path="/setpass/:slug" exact component={SocialLinkSetPassword} props={childProps} />
      <AppRoute path="/changepass" exact component={AsyncChangePassword} props={childProps} />
      <AppRoute path="/social" exact component={AsyncSocialLogin} props={childProps} />
      <AppRoute path="/profile" exact component={AsyncProfile} props={childProps} />
      <AppRoute path="/iv-report" exact component={AsyncIvReport} props={childProps} />
      <AppRoute component={AsyncNotFound} />
    </Switch>);
};