import axios from 'axios';
import customFunctions from '../../util/customFunctions';

export default {
  setupInterceptors: async () => {
    await axios.interceptors.request.use(async (config) => {
      config.headers['Content-Type'] = 'application/json';
      const token = await customFunctions.getDataFromCookie('usertoken');
      const authkey = await customFunctions.getDataFromCookie('crypto_userToken');
      if (token) config.headers['x-access-token'] = `${token}`; // eslint-disable-line
      if (authkey) config.headers['authkey'] = `${authkey}`;
      return config;
    }, (error) => {
      Promise.reject(error);
    });
    // axios.interceptors.response.use(response => response, (error) => {
    //   return Promise.reject(error.response);
    // });

    await axios.interceptors.response.use(response => response, async (error) => {
      const data = await error.response;
      if (data && (data.status === 401 || (data.statusText === 'Unauthorized'))) {
        await customFunctions.doIfUserIsUnauthorized();
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.response);
    });
  }
};