import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caption: "Loading..."
        }
    }

    render() {
        const { showLoader, title, showApiLoader, needFullPage } = this.props;
        const { caption } = this.state;
        return (
            <React.Fragment>
                {
                    showLoader && (
                        <div className="SpinnerWrapper">
                            <Spinner animation="border" role="status" size="l" variant="primary">
                            </Spinner>
                            <br />
                            <span className="SpinderCaption">{title ? title : caption}</span>
                        </div>
                    )
                }
                {
                    (showApiLoader) ? (
                        <div className={(needFullPage) ? 'loader-overlay full-loader' : 'loader-overlay'}>
                            <div className="loader">
                                <Spinner animation="border" role="status" size="l" variant="primary">
                                </Spinner>
                            </div>
                        </div>
                    ) : null
                }
            </React.Fragment>
        );
    }
}

export default withRouter(Loader);